* {
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
}
