.form-container {
  width: 700px;
  margin: auto;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
  border-radius: 10px;

  position: relative;
  height: 400px;
  .form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 2rem;
      text-align: start;
      width: 80%;
      margin-bottom: 1rem;
      color: rgb(0, 0, 0);
    }
    .form-inputs {
      margin-bottom: 0.5rem;
      width: 80%;
    }

    .form-inputs p {
      font-size: 0.8rem;
      margin-top: 0.5rem;
      color: #f00e0e;
    }

    .form-label {
      display: inline-block;
      font-size: 0.8rem;
      margin-bottom: 6px;
      color: #000;
    }

    .form-input {
      display: block;
      padding-left: 10px;
      outline: none;
      border-radius: 2px;
      background-color: rgb(235, 235, 235);
      height: 40px;
      width: 100%;
      border: none;
    }

    .form-input::placeholder {
      color: #595959;
      font-size: 14px;
    }

    .form-input-btn {
      width: 80%;
      height: 50px;
      margin-top: 10px;
      border-radius: 2px;
      border: 2px solid black;
      background-color: white;
      color: #000;
      font-size: 1rem;
      border-color: #04aa6d;
      color: green;
    }

    .form-input-btn:hover {
      cursor: pointer;
      background-color: #2196f3;
      color: white;
      transition: all 0.4s ease-out;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .form-container {
    width: 320px;
    margin: auto;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);

    border-radius: 10px;

    position: relative;
    height: 300px;
    grid-template-columns: 1fr;
    .form {
      grid-template-columns: 1fr;
      h1 {
        font-size: 2rem;
        text-align: start;
        width: 90%;
        margin-bottom: 1rem;
        color: rgb(0, 0, 0);
      }
      .form-inputs {
        margin-bottom: 0.5rem;
        width: 90%;
      }

      .form-inputs p {
        font-size: 1rem;
        margin-top: 0.5rem;
        color: #f00e0e;
      }

      .form-label {
        display: inline-block;
        font-size: 1.5rem;
        margin-bottom: 6px;
        color: #000;
      }

      .form-input {
        display: block;
        padding-left: 10px;
        outline: none;
        border-radius: 2px;
        background-color: rgb(235, 235, 235);
        height: 40px;
        width: 100%;
        border: none;
      }

      .form-input::placeholder {
        color: #595959;
        font-size: 14px;
      }

      .form-input-btn {
        width: 80%;
        height: 50px;
        margin-top: 10px;
        border-radius: 2px;
        background: linear-gradient(
          90deg,
          rgb(39, 176, 255) 0%,
          rgb(0, 232, 236) 100%
        );
        outline: none;
        border: none;
        color: #000;
        font-size: 1rem;
      }

      .form-input-btn:hover {
        cursor: pointer;
        background: linear-gradient(
          90deg,
          rgb(39, 143, 255) 0%,
          rgb(12, 99, 250) 100%
        );
        transition: all 0.4s ease-out;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .form-container {
    width: 420px;
    margin: auto;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);

    border-radius: 10px;

    position: relative;
    height: 300px;
    grid-template-columns: 1fr;
    .form {
      grid-template-columns: 1fr;
      h1 {
        font-size: 2rem;
        text-align: start;
        width: 90%;
        margin-bottom: 1rem;
        color: rgb(0, 0, 0);
      }
      .form-inputs {
        margin-bottom: 0.5rem;
        width: 90%;
      }

      .form-inputs p {
        font-size: 1rem;
        margin-top: 0.5rem;
        color: #f00e0e;
      }

      .form-label {
        display: inline-block;
        font-size: 1.5rem;
        margin-bottom: 6px;
        color: #000;
      }

      .form-input {
        display: block;
        padding-left: 10px;
        outline: none;
        border-radius: 2px;
        background-color: rgb(235, 235, 235);
        height: 40px;
        width: 100%;
        border: none;
      }

      .form-input::placeholder {
        color: #595959;
        font-size: 14px;
      }

      .form-input-btn {
        width: 80%;
        height: 50px;
        margin-top: 10px;
        border-radius: 2px;
        background: linear-gradient(
          90deg,
          rgb(39, 176, 255) 0%,
          rgb(0, 232, 236) 100%
        );
        outline: none;
        border: none;
        color: #000;
        font-size: 1rem;
      }

      .form-input-btn:hover {
        cursor: pointer;
        background: linear-gradient(
          90deg,
          rgb(39, 143, 255) 0%,
          rgb(12, 99, 250) 100%
        );
        transition: all 0.4s ease-out;
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .form-container {
    width: 600px;
    margin: auto;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);

    border-radius: 10px;

    position: relative;
    height: 500px;
    grid-template-columns: 1fr;
    .form {
      grid-template-columns: 1fr;
      h1 {
        font-size: 2rem;
        text-align: start;
        width: 90%;
        margin-bottom: 1rem;
        color: rgb(0, 0, 0);
      }
      .form-inputs {
        margin-bottom: 0.5rem;
        width: 90%;
      }

      .form-inputs p {
        font-size: 2rem;
        margin-top: 0.5rem;
        color: #f00e0e;
      }

      .form-label {
        display: inline-block;
        font-size: 1rem;
        margin-bottom: 6px;
        color: #000;
      }

      .form-input {
        display: block;
        padding-left: 10px;
        outline: none;
        border-radius: 2px;
        background-color: rgb(235, 235, 235);
        height: 60px;
        width: 100%;
        border: none;
        font-size: 20px;
      }

      .form-input::placeholder {
        color: #595959;
        font-size: 20px;
      }

      .form-input-btn {
        width: 80%;
        height: 50px;
        margin-top: 10px;
        border-radius: 2px;
        background: linear-gradient(
          90deg,
          rgb(39, 176, 255) 0%,
          rgb(0, 232, 236) 100%
        );
        outline: none;
        border: none;
        color: #000;
        font-size: 1rem;
      }

      .form-input-btn:hover {
        cursor: pointer;
        background: linear-gradient(
          90deg,
          rgb(39, 143, 255) 0%,
          rgb(12, 99, 250) 100%
        );
        transition: all 0.4s ease-out;
      }
    }
  }
}
