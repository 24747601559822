@import "../utils/colors.scss";
.custom-shape-divider-bottom-1629022903 {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1629022903 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 58px;
}

.custom-shape-divider-bottom-1629022903 .shape-fill {
  fill: #ffe0e0;
}

.OurTeam-container {
  padding-top: 2rem;
  background-image: linear-gradient(#feffff, #feffff);
  align-items: center;
  justify-content: center;

  .container {
    padding-bottom: 2rem;
    margin-top: 1rem;
    align-items: center;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .details-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0;
      margin-bottom: 0;
      .title {
        h2 {
          color: $headingColor;
        }
      }
      p {
        color: $headingColor;
        text-align: center;
        justify-content: center;
        margin-bottom: 1rem;
      }
      .subTitle {
        font-size: large;
        color: #4e5052;
        text-transform: capitalize;
      }
    }

    .teamMembers-cards {
      margin-bottom: 2rem;
      display: grid;
      gap: 3rem;

      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .OurTeam-container {
    .container {
      grid-template-columns: 1fr;
      .details-container {
        p {
          font-size: medium;
          line-height: 2rem;
          width: 100%;
        }
      }
      .teamMembers-cards {
        grid-template-columns: 1fr;
      }
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .OurTeam-container {
    .container {
      .details-container {
        p {
          font-size: medium;
          line-height: 1.7rem;
          width: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .OurTeam-container {
    .container {
      gap: 1rem;
      .teamMembers-cards {
        gap: 1rem;
      }
    }
  }
}
