@import "../utils/colors.scss";

.card {
  height: 12rem;
  width: 12rem;
  background-color: white;
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.4s ease-in-out;
  .card-logo {
    padding-bottom: 0;
    width: 9rem;
    height: 9rem;
    display: flex;
    margin-left: 1rem;
    img {
      position: absolute;

      width: inherit;
    }
  }
  .card-title {
    margin-top: 0.5rem;
    text-align: center;
    font-size: x-large;
    color: $pinkColor;
  }
  &:hover {
    box-shadow: #f32a8f2f 5px 5px, #f32a8f2f 5px 10px, #f32a8f02 15px 15px;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .card {
    height: 12rem;
    width: 65vw;
    padding: 3rem 3rem;

    .card-logo {
      width: 8rem;
      height: 8rem;
      display: flex;

      img {
        position: absolute;
        width: inherit;
      }
    }

    .card-title {
      text-align: start;

      font-size: x-large;
      color: $pinkColor;
    }
    &:hover {
      box-shadow: #f32a8f2f 5px 5px, #f32a8f2f 5px 10px, #f32a8f02 15px 15px;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .card {
    height: 8rem;
    width: 13vw;
    padding: 3rem 3.5rem;

    .card-logo {
      height: 4rem;
      width: 4rem;
      img {
        position: absolute;
        width: inherit;
        font-size: 2rem;
      }
    }
    .card-title {
      margin-bottom: 1rem;
      font-size: small;
      color: rgb(18, 18, 88);
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .card {
    height: 8rem;
    width: 13rem;
    .card-logo {
      img {
        position: absolute;
        width: inherit;
        font-size: 2rem;
      }
    }
  }
}
