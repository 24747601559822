@import "../utils/colors.scss";

.services-container {
  background-image: linear-gradient(
    to bottom,
    #f1fdff,
    #f3fdff,
    #f6fdff,
    #f8feff,
    #fafeff,
    #fbfeff,
    #fdfeff,
    #fefeff,
    #fefeff,
    #fffeff,
    #ffffff,
    #ffffff
  );
  .services-info {
    display: grid;
    align-items: center;

    ul {
      width: 80%;
      list-style-type: none;

      li {
        font-size: 20px;
        color: $lightFontColor;
        margin-bottom: 1.5rem;
        margin-top: 1rem;
        padding-left: 45px;

        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          width: 30px;
          height: 30px;
          background-image: url("../../assets/check-circle.svg");
          background-repeat: no-repeat;
        }
      }
    }
  }
  .custom-shape-divider-bottom-1629022234 {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1629022234 svg {
    position: relative;
    display: block;
    width: calc(124% + 1.3px);
    height: 30px;
  }

  .custom-shape-divider-bottom-1629022234 .shape-fill {
    fill: #f9feff;
  }
  .container {
    margin: 0 10%;
    padding: 50px 0;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    .details {
      h3 {
        color: $lightFontColor;
        line-height: 1.3rem;
        font-size: large;

        width: 90%;
      }
    }

    .cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .services-container {
    .services-info {
      margin-top: 1rem;
      li {
        font-size: 2rem;
      }
    }
    .container {
      grid-template-columns: 1fr;

      .details {
        p {
          font-size: 2.3rem;
          line-height: 2rem;
          width: 100%;
          height: auto;
        }
      }

      .cards {
        grid-template-columns: 1fr;
      }
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .services-container {
    .container {
      .details {
        p {
          font-size: 1.8rem;
          line-height: 1.7rem;
          width: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .services-container {
    .container {
      gap: 1rem;
      .cards {
        gap: 1rem;
      }
    }
  }
}
