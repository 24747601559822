@import "../utils/colors.scss";
.about-details {
  align-items: center;
  justify-content: center;
  background-color: #fdfdff;
  display: flexbox;
  flex-direction: column;
  text-align: justify;
  margin: 25px;
  padding: 2rem;
  .title {
    margin-top: 2rem;
    padding-top: 2rem;
  }
  p {
    font-size: 2rem;
  }
}
.about-container {
  padding-top: 2rem;

  .DhanrajImg-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      margin-top: 0;
      padding: 8px;

      text-align: center;
    }
  }
}

.Dhanraj-img {
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
