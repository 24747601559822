@import "../utils/colors.scss";

.CompanyInfo-container {
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-right: 10%;
  padding-left: 10%;
  .container {
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .details-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0;
      margin-bottom: 0;
    }
    .info-cards {
      margin-bottom: 2rem;
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(3, 1fr);
      background-color: rgb(255, 255, 255);

      .title-content {
        h2 {
        }
        h4 {
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .CompanyInfo-container {
    .container {
      grid-template-columns: 1fr;
      .logo {
        svg {
          width: 1rem;
          height: auto;
        }
      }
      .info-cards {
        grid-template-columns: 1fr;
        .title-content {
          h3 {
            font-size: 1.5rem;
          }
          h4 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .CompanyInfo-container {
    .container {
      grid-template-columns: 1fr;
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .CompanyInfo-container {
    .container {
      gap: 1rem;
      .details-container {
        gap: 1rem;
      }
    }
  }
}
