@import "../../styles/utils/colors.scss";
.footer-container {
  background-color: #333335;
  color: white;
  min-height: 55vh;
  height: fit-content;
  padding: 8rem 0;
  .main-container {
    margin: 0 10%;

    padding-top: 2.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        margin: 1rem 0;
        a {
          color: #b5b8b8;
          text-decoration: none;
          &:hover {
            color: white;
          }
        }
      }
    }

    .touch {
      h3 {
        margin-bottom: 1rem;
      }
      .touch-section {
        margin-bottom: 1rem;
        display: flex;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #2c2c31;
          border-radius: 0.3rem;
          height: 3rem;
          width: 3rem;
          margin-right: 1rem;
          svg {
            font-size: 1.5rem;
          }
        }
        .detail {
          .detail-name {
          }
          .detail-content {
            a {
              text-align: justify;
              text-decoration: none;
              color: #b5b8b8;
              &:hover {
                color: white;
              }
            }
          }
        }
      }
    }
  }
  .copyright {
    margin: 5% 10% 0 10%;

    display: flex;
    justify-content: space-between;
    border-top: #b5b8b8 1px solid;
    .copy {
      color: #b5b8b8;
    }
    .icons {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.5rem;
        width: 2.5rem;
        background-color: #2c2c31;
        border-radius: 0.3rem;
        margin-left: 0.3rem;
        transition: 0.5s ease-in-out;
        svg {
          font-size: 1.2rem;
          color: white;
        }
        &:hover {
          background-color: white;
          svg {
            color: #2c2c31;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .footer-container {
    .main-container {
      grid-template-columns: 1fr;
      h3 {
        font-size: 2.4rem;
      }
      ul {
        margin-bottom: 2rem;
        li {
          font-size: 1.5rem;
        }
      }
      .news-letter {
        margin-bottom: 2rem;
        p {
          font-size: 1.7rem;
        }
        .mail {
          input {
            height: 4.5rem;
          }
          button {
            height: 4.7rem;
          }
        }
      }
      .touch {
        .touch-section {
          .icon {
            height: 5rem;
            width: 5rem;
            svg {
              font-size: 2rem;
            }
          }
        }
        .detail {
          .detail-name {
            font-size: medium;
          }
          .detail-content {
            font-size: small;
          }
        }
      }
    }
    .copyright {
      border-top-width: 0.5rem;
      flex-direction: column;
      .copy {
        p {
          font-size: 2rem;
        }
      }
      .icons {
        margin-bottom: 1rem;
        .icon {
          height: 4rem;
          width: 4rem;
          margin-right: 1rem;
          svg {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .footer-container {
    min-height: 30vh;
    .main-container {
      ul {
        margin-bottom: 2rem;
        li {
          font-size: 1.5rem;
        }
      }
      .news-letter {
        // margin-bottom: 2rem;
        p {
          font-size: 1.3rem;
          width: 90%;
        }
      }
      .touch {
        .touch-section {
          &:last-child {
            .icon {
              height: 5rem;
              width: 6rem;
            }
          }
          .icon {
            svg {
              height: 5rem;
              width: 5rem;
              font-size: 5rem;
            }
          }
        }
        .detail {
          .detail-name {
            font-size: medium;
          }
          .detail-content {
            font-size: x-small;
          }
        }
      }
    }
    .copyright {
      border-top-width: 0.2rem;
      .copy {
        p {
          font-size: 2rem;
        }
      }
      .icons {
        margin-bottom: 1rem;
        .icon {
          height: 4rem;
          width: 4rem;
          margin-right: 1rem;
          svg {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .footer-container {
    min-height: 30vh;
  }
}
