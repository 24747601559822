@import "../utils/colors.scss";

button {
  color: white;
  text-transform: uppercase;
  padding: 0.8rem 1rem;
  border: 0.1rem solid transparent;
  border-radius: 0.25rem;
  font-weight: bolder;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;

  svg {
    margin-left: 0.5rem;
    font-size: large;
  }
}

.blue {
  background-color: $blueColor;
  &:hover {
    background-color: white;
    border: 0.1rem solid $blueColor;
    color: $blueColor;
  }
}
